export const ru = {
  nav_1: `Главная`,
  nav_2: `Все направлении`,
  nav_3: `О нас`,
  nav_4: `Контакты`,
  nav_5: `Регистрация`,
  nav_6: `Войти`,
  nav_7: `Помощь в визе`,

  header_box_1: `путешествуйте мир`,
  header_box_2: `Давайте путешествовать по`,
  header_box_3: `миру`,
  header_box_4: `Ознакомьтесь с`,
  header_box_5: `"Silk Asia"`,
  header_box_6: `вместе со своим партнером по путешествиям! Сделайте свой отпуск веселым, захватывающим и незабываемым.`,
  header_box_7: `Забронировать сейчас`,
  header_1: `Качественные и надежные гарантированные паломничества Умра`,

  h_plan_name_1: `Начните планировать свое путешествие`,
  h_plan_name_2: `Все, что вам нужно знать, чтобы начать планировать свое путешествие в Мекку и Медину.`,

  h_ab_name_1: `О компании "Silk Asia"`,
  h_ab_name_2: `Платформа для целостного путешествия`,
  h_ab_name_3: `Используйте "Silk Asia", первую в истории официальную платформу планирования, бронирования и опыта, для создания маршрута хаджа или умры в Мекку, Мадину и другие места. С помощью "Silk Asia" путешественники со всего мира могут легко организовать весь свой визит, начиная с подачи заявления на получение электронной визы и заканчивая бронированием отелей и авиабилетов. В будущем "Silk Asia" также можно будет использовать для планирования посещения важных объектов, поиска транспорта, составления маршрутов, а также для доступа к инструментам на месте, таким как трекер Тавафа и др.`,

  btn: `Подробнее`,

  h_tour_h: `Специальные предложения и направления`,

  feed_name_1: `Связаться со специалистом`,
  feed_name_2: `Ваше имя`,
  feed_name_3: `Ваш номер телефона:`,
  feed_name_4: `Содержание сообщения:`,
  feed_name_5: `Отправить`,

  map_h: `нужно больше информации? Связаться с нами.`,

  foot_h_1: `Настройки`,
  foot_h_2: `Скачать приложение "Silk Asia"`,

  head_h_1: `Визовая поддержка, трансфер, медицинская страховка`,
  head_h_2: `Визовая помощь`,
  head_h_3: `Список необходимых документов:`,
  head_h_4: `Биометрический паспорт для выезда за границу`,
  head_h_5: `2 цветные фотографии`,
  head_h_6: `4х6 на белом фоне (женщины в платке)`,
  head_h_7: `Сертификат о прививках (прививочный сертификат) `,
  head_h_8: `Здоровье`,
  head_h_9: `Платежеспособность`,
  head_h_10: `Духовная подготовка к паломничеству `,
  head_h_11: `Наличие махрама у женщин до 45 лет`,
  head_h_12: `Трансфер`,
  head_h_13: `Автобусы и автомобили, обслуживающие наших паломников в Мекке и Медине`,
  head_h_14: `Медицинская страховка`,
  head_h_15: `Что мы получаем с государственной медицинской страховкой? Прежде всего, будет достигнуто раннее выявление заболеваний, дальнейшее повышение качества и объема медицинских услуг, оказываемых населению. При гарантированных пакетах бесплатные медицинские услуги, гарантированные государством, и доставка лекарств осуществляются на уровне каждого медицинского учреждения. В рамках пакета гражданин проходит необходимые диагностические и лабораторные исследования в областном кабинете семейного врача, семейной поликлинике, многопрофильной центральной поликлинике. Эти пакеты не имеют срока годности и являются универсальными.`,

  h_plan_con_h: `Получить консультацию`,
  ppay: `Оплатить`,

  pay_a: `Отправить`,

  pay_card_name: "Введите данные карты",

  town_text_h_1: `Лучшее для`,
  town_text_h_2: `Религии`,
  town_text_h_3: `Культура`,
  town_text_h_4: `История`,
  town_text_h_5: `Наследие`,

  town_name: `Исследуйте Мекку`,
  town_name_2: `Исследуйте Мадину`,

  town_1_2_box_1: `Проживание`,
  town_1_2_box_2: `Святые места`,
  town_1_2_box_3: `Достопримечательности`,
  town_1_2_box_4: `Магазины`,
  town_1_2_box_5: `Еда и напитки`,
  town_1_2_box_6: `Как добраться до Мекки`,
  town_1_2_box_6_2: `Как добраться до Мадины`,

  town_1_info_h_1: `Святое место`,
  town_1_info_h_2: `Мекка - самый святой город для мусульман. В нем находятся Масджид аль-Харам (Великая мечеть) и Кааба - кибла для мусульман, к которой они обращаются в своих
  молитвах. Мусульмане стремятся посетить Мекку, чтобы совершить Хадж или Умру. В этом городе родился пророк Мухаммад, самый почетный из творений, печать
  пророков и посланников, наш господин Мухаммад, мир ему, и из него толерантное послание ислама было отправлено во все уголки мира после того, как был раскрыт
  Священный Коран. Мекка расположена в западной части Саудовской Аравии. Во времена правления королей Саудовской Аравии она пользовалась большим интересом.
  Мекка и сейчас пользуется большим вниманием, предоставляя паломникам самые лучшие услуги. Ее самая выдающаяся достопримечательность, Масджид аль-Харам,
  стала свидетелем многочисленных расширений, включающих все части мечети, а в святых местах был проведен ряд работ по благоустройству, чтобы обеспечить все
  средства комфорта и уверенности для всех паломников изнутри и из-за пределов Королевства.`,

  town_1_info_h_3: `Аль Мадина Аль Мунавара`,
  town_1_info_h_4: `Мадина, или, как ее называют мусульмане, Тайба аль-Тайба", является одним из главных пунктов назначения для миллионов мусульман во всем мире. Это первая в
  истории столица ислама и второе по святости место для мусульман после Мекки аль-Мукаррама.`,
  headpro_name: `Выгодные предложения`,
  headpro_name_2: ` Популярные месты`,

  h_about_h: `О нас`,
  h_about_sub: ` С Silk Asia каждый уголок планеты стал гораздо ближе и доступнее.`,
  h_about_p_1: `Весь мир на ладони. С Silk Asia каждый уголок планеты стал гораздо ближе и доступнее. Путешествия открывают нам заново мир каждый раз, как только мы посещаем другую страну. Всё удивительное рядом, достаточно только решиться и сделать первый шаг.`,
  h_about_p_2: `Наш новый дизайн отражает иной взгляд, совершенно новый подход к вашему отдыху. Это прекрасная возможность пополнить свой багаж новыми знаниями, изучив культуру и традиции других народов. С нами вы сможете посетить уникальные места нашей планеты, познакомиться с интересными людьми и узнать вкус настоящего турецкого кофе, цейлонского чая, высоту египетских пирамид.`,

  head_info_name: `Что у нас особенного`,
  h_info_text_h_1: `Silk Asia`,
  h_info_text_p_1: `Мы четко понимаем ситуацию в мире, ввиду пандемии, поэтому все наши предложения о путешествиях основаны на безопасности и стабильности.`,
  h_info_text_h_2: `Индивидуальный подход`,
  h_info_text_p_2: `Мы организовываем туристические поездки, как и для индивидуальных туристов, так и для больших корпоративных групп с целью деловых или развлекательных событий.`,
  h_info_text_h_3: `На связи 24/7`,
  h_info_text_p_3: `Мы заключаем договоры сотрудничества только с проверенными и надежными туроператорами, которые остаются на связи с нашими гостями 24/7.`,
  h_info_text_h_4: `Лучшие цены`,
  h_info_text_p_4: `Мы постоянно отслеживаем актуальность цен и уникальность предложений. Мы сформировали большую базу лучших отелей по всему миру в зависимости от бюджетов наших клиентов`,

  h_contact_name: `Связаться с нами`,
  c_head_name_1: `Телефон номер`,
  c_head_name_2: `Время работы`,
  c_head_name_3: `Понедельник - суббота с 9:00 до 18: 00`,
  c_head_name_4: `Адрес`,
  c_head_name_5: `Город Ташкент, Яшнабадский район, Улица Ташсельмаш 12.`,

  footer_box_name: `ИНФОРМАЦИЯ`,
  tour: `ТУРЫ`,
  headpro_name_3: `Все направлении`,

  registr_name_1: `Регистрация`,
  registr_name_2: `Войти`,

  registr_h_1: `Ваш номер телефона *`,
  registr_h_2: `Установите пароль *`,
  registr_h_3: `Подтвердите пароль  *`,
  registr_h_4: ` Пароль *`,
  registr_h_5: ` Забыли пароль?`,

  cab_name: `Выберите дату`,
  cal_pay_h: `Оплатить`,

  pay_name: ` Выберите тип оплаты`,

  from: `от`,

  detail_order_btn: `Забронировать сейчас`,
  town_name: `с вылетом из`,
  town_h: `Даты туров:`,
  town_h2: ` *цена может меняться в соответствии с курсом ЦБ"`,

  login_false: `Введите информацию правильно`,
  log_out: `Выйти`,
  sum: `сум`,

  card_1: `Провести платеж`,
  card_2: `Сумма платежа`,
  card_3: `Введите номер карты`,
  card_4: `Срок действия`,
  card_5: `Подтвердить`,
  card_6: `Введите код подтверждения`,
  card_7: `Ваш платеж успешно произведен`,

  visa_a_1: `Получить консультацию`,
  visa_box_name: `ПОЧЕМУ НАС ВЫБИРАЮТ?`,
  visa_box_name_1: `Доступное приобретение визы`,
  visa_box_name_2: `Поможем с приобретением визы в другую страну с максимальной эффективностью.`,
  visa_box_name_3: `Виза в большинство стран`,
  visa_box_name_4: `Профессиональная визовая поддержка в выбранную Вами страну.`,
  visa_box_name_5: `Выгодные предложения`,
  visa_box_name_6: `Подберем для Вас самые выгодные предложения по визам и визовой поддержке`,

  visa_p_1: `Визовая поддержка`,
  visa_p_2: `Визовая поддержка – услуга по подготовке визовых документов и консультации по всем вопросам, связанным с оформлением, получением необходимых документов для открытия виз.
 `,
  visa_p_3: ` Визовая поддержка чаще всего оказывается иностранному гражданину консульством той страны, куда он планирует или хочет приехать, за рубежом, Турция, Египет и другие страны. Оформление визы в таких случаях на основании официального приглашения, оформленного в установленном законодательством той страны, которую иностранец планирует посетить. Приглашение может быть оформлено, как по приглашению физического лица, так и юридического, агентства или центра, оказывающего Визовую поддержку.`,
};
