export const uz = {
  nav_1: `Asosiy`,
  nav_2: `Barcha yo'nalishlar`,
  nav_3: `Biz haqimizda`,
  nav_4: `Kontaktlar`,
  nav_5: `Ro‘yxatdan o‘tish`,
  nav_6: `Kirish`,
  nav_7: `Viza bilan yordam`,

  header_box_1: `dunyo bo'ylab sayohat qilish`,
  header_box_2: `Keling`,
  header_box_3: `dunyoni`,
  header_box_4: ``,
  header_box_5: `"Silk Asia"`,
  header_box_6: ` bilan sayohat qiling ! Bayramingizni qiziqarli va unutilmas o'tkazing.`,
  header_box_7: `Bron qilish`,

  header_box_22: `sayr qilamiz`,

  headpro_name: `Ajoyib takliflar`,
  headpro_name_2: `Mashxur joylar`,

  h_about_h: `Biz haqimizda`,
  h_about_sub: ` Silk Asia bilan sayyoramizning har bir burchagi yanada yaqinroq va qulayroq bo'ldi.`,
  h_about_p_1: `Butun dunyo sizning kaftingizda. Silk Asia bilan sayyoramizning har bir burchagi yanada yaqinroq va qulayroq bo'ldi. Har safar boshqa davlatga tashrif buyurganimizda sayohat biz uchun dunyoni ochadi. Ajoyib hamma narsa yaqin, faqat qaror qabul qilish va birinchi qadamni tashlash kerak.`,
  h_about_p_2: `Bizning yangi dizaynimiz bayramingizga boshqa nuqtai nazarni, mutlaqo yangicha yondashuvni aks ettiradi. Bu boshqa xalqlarning madaniyati va an'analarini o'rganib, yukingizni yangi bilimlar bilan to'ldirish uchun ajoyib imkoniyatdir. Biz bilan siz sayyoramizning noyob joylariga tashrif buyurishingiz, qiziqarli insonlar bilan tanishishingiz va haqiqiy turk qahvasi, Seylon choyi, Misr piramidalarining balandligi bilan tanishishingiz mumkin.`,

  head_info_name: "Bizda nima o'zgacha",
  h_info_text_h_1: `Silk Asia`,
  h_info_text_p_1: `Biz pandemiya sababli dunyodagi vaziyatni aniq tushunamiz, shuning uchun barcha sayohat takliflarimiz xavfsizlik va barqarorlikka asoslangan.`,
  h_info_text_h_2: `Individual yondashuv`,
  h_info_text_p_2: `Biz biznes yoki dam olish tadbirlarini o'tkazish maqsadida individual turistlar va yirik korporativ guruhlar uchun turistik sayohatlarni tashkil qilamiz.`,
  h_info_text_h_3: `24/7 aloqada`,
  h_info_text_p_3: `Biz hamkorlik shartnomalarini faqat mehmonlarimiz bilan 24/7 aloqada bo'ladigan, tasdiqlangan va ishonchli turoperatorlar bilan tuzamiz.`,
  h_info_text_h_4: `Eng yaxshi narxlar`,
  h_info_text_p_4: `Biz narxlarning dolzarbligini va takliflarning o'ziga xosligini doimiy ravishda kuzatib boramiz. Biz mijozlarimizning byudjetiga qarab, dunyodagi eng yaxshi mehmonxonalarning katta ma'lumotlar bazasini shakllantirdik.`,

  h_contact_name: "Biz bilan bog'laning",
  c_head_name_1: `Telefon raqami`,
  c_head_name_2: "Ish soatlari",
  c_head_name_3: `Dushanba - Shanba kunlari soat 9:00 dan 18:00 gacha`,
  c_head_name_4: `Manzil`,
  c_head_name_5: `Toshkent shahri, Yashnobod tumani, Toshselmash ko‘chasi, 8-uy.`,

  footer_box_name: `Ma'lumotlar`,
  tour: `TURLAR`,
  headpro_name_3: `Barcha yo'nalishlar`,

  registr_name_1: `Ro'yxatdan o'tish`,
  registr_name_2: `Kirish`,

  registr_h_1: `Telefon raqamingiz *`,
  registr_h_2: `Parolni o'rnatish *`,
  registr_h_3: `Parolni tasdiqlang *`,
  registr_h_4: `Parol *`,
  registr_h_5: `Parolni unutdingizmi?`,

  cab_name: `Sanani tanlang`,
  cal_pay_h: `To'lash`,

  pay_name: `To'lov turini tanlang`,

  from: ``,

  detail_order_btn: `Band qilish`,
  shahar_nomi: `ketish joyi`,
  town_h: `Sayohat sanalari:`,
  town_h2: ` *narx Markaziy bank kursiga ko'ra o'zgarishi mumkin"`,

  login_false: `Ma'lumotlarni to'gri kiriting`,
  log_out: `Chiqish`,
  sum: `so'm`,

  card_1: `To'lovni amalga oshirish`,
  card_2: `To'lov summasi`,
  card_3: `Karta raqamingizni kiriting`,
  card_4: `Amal qilish muddati`,
  card_5: `Tasdiqlash`,
  card_6: `Tasdiqlash kodini kiriting`,
  card_7: `To'lovingiz muvaffaqiyatli amalga oshirildi`,

  visa_a_1: `Maslahat olish`,
  visa_box_name: `NEGA BIZ?`,
  visa_box_name_1: `Mavjud viza xaridi`,
  visa_box_name_2: `Biz sizga maksimal samaradorlik bilan boshqa davlatga viza olishingizga yordam beramiz.`,
  visa_box_name_3: `Dunyo mamlakatlariga vizalar`,
  visa_box_name_4: `Siz tanlagan mamlakatga professional viza yordami.`,
  visa_box_name_5: `Ajoyib takliflar`,
  visa_box_name_6: `Biz siz uchun vizalar va vizani qo'llab-quvvatlash bo'yicha eng qulay takliflarni tanlaymiz`,
  h_plan_name_1: `Sayohatingizni rejalashtirishni boshlang`,
  h_plan_name_2: `Makka va Madinaga sayohatingizni rejalashtirishni boshlash uchun bilishingiz kerak bo'lgan hamma narsa.`,

  h_ab_name_1: `"Silk Asia" kompaniyasi haqida`,
  h_ab_name_2: `Yaxlit sayohat uchun platforma`,
  h_ab_name_3: `Makka, Madina va boshqa joylarga Haj yoki Umra yo'nalishini yaratish uchun tarixdagi birinchi rasmiy rejalashtirish, bron qilish va tajriba platformasi bo'lgan "Silk Asia" dan foydalaning. "Silk Asia" bilan dunyoning turli burchaklaridan kelgan sayohatchilar elektron vizaga murojaat qilishdan tortib mehmonxonalar va reyslarni bron qilishgacha bo‘lgan barcha tashriflarini bemalol tashkil qilishlari mumkin. Kelajakda “Silk Asia” muhim joylarga tashrif buyurishni rejalashtirish, transport topish, marshrutlarni yaratish, shuningdek, tavof kuzatuvchisi kabi saytdagi vositalardan foydalanish uchun ham ishlatilishi mumkin`,

  btn: `Batafsil`,

  h_tour_h: `Maxsus takliflar va yo'nalishlar`,

  feed_name_1: `Mutaxassis bilan bog'laning`,
  feed_name_2: `Sizning ismingiz`,
  feed_name_3: `Telefon raqamingiz:`,
  feed_name_4: `Xabar mazmuni:`,
  feed_name_5: `Yuborish`,

  map_h: `qo'shimcha ma'lumot kerakmi? Biz bilan bog'laning.`,

  foot_h_1: "Sozlamalar",
  foot_h_2: `"Silk Asia" ilovasini yuklab oling`,

  head_h_1: `Vizani qoʻllab-quvvatlash, transfer, tibbiy sugʻurta`,
  head_h_2: "Viza yordami",
  head_h_3: `kerakli hujjatlar ro'yxati:`,
  head_h_4: `Chet elga chiqish uchun biometrik pasport`,
  head_h_5: "2 ta rangli fotosurat",
  head_h_6: `oq fonda 4x6 (ro'mol o'ragan ayollar)`,
  head_h_7: `Vaktsinatsiya sertifikati (emlash sertifikati)`,
  head_h_8: `Salomatlik`,
  head_h_9: `To'lov qobiliyati`,
  head_h_10: `Hajga ma'naviy tayyorgarlik`,
  head_h_11: `45 yoshgacha bo'lgan ayollarda mahram borligi`,
  head_h_12: `Transfer`,
  head_h_13: `Makka va Madinadagi ziyoratchilarimizga xizmat qiluvchi avtobus va avtomashinalar`,
  head_h_14: `Tibbiy sug'urta`,
  head_h_15: `Davlat tibbiy sug'urtasi bilan biz nima olamiz? Avvalo, kasalliklarni barvaqt aniqlash, aholiga ko‘rsatilayotgan tibbiy xizmat sifati va hajmini yanada yaxshilashga erishiladi. Kafolatlangan paketlar bilan davlat tomonidan kafolatlangan bepul tibbiy xizmatlar va dori vositalarini yetkazib berish har bir tibbiyot muassasasi darajasida amalga oshirilmoqda. Paket doirasida fuqaro hududiy oilaviy shifokor, oilaviy poliklinika, ko‘p tarmoqli markaziy poliklinikada zarur diagnostika va laboratoriya tekshiruvlaridan o‘tadi. Bu paketlarning yaroqlilik muddati yo‘q va umumiydir.`,

  h_plan_con_h: `Maslahat olish`,

  ppay: `To'lash`,

  header_1: `Sifatli va ishonchli kafolatlangan Umra ziyoratlari`,

  pay_a: `Yuborish`,

  pay_card_name: "Karta ma’lumotlarini kiriting",

  town_text_h_1: `Eng yaxshisi uchun`,
  town_text_h_2: "Dinlar",
  town_text_h_3: "Madaniyat",
  town_text_h_4: "Tarix",
  town_text_h_5: `Avlodlar`,

  town_name: "Makkani o'rganing",
  town_name_2: "Madinani o'rganing",

  town_1_2_box_1: `Turar joy`,
  town_1_2_box_2: "Muqaddas joylar",
  town_1_2_box_3: `Qiziqarli joylar`,
  town_1_2_box_4: `Do‘konlar`,
  town_1_2_box_5: `Oziq-ovqat va ichimliklar`,
  town_1_2_box_6: `Makkaga qanday borish mumkin`,
  town_1_2_box_6_2: `Madinaga qanday borish mumkin`,

  town_1_info_h_1: "Muqaddas joy",
  town_1_info_h_2: `Makka musulmonlar uchun eng muqaddas shahar. Unda Masjid al-Harom (Ulug‘ masjid) va musulmonlar uchun qibla bo‘lmish Ka’ba joylashgan bo‘lib, ular o‘zlarida nazarda tutilgan.
  ibodatlar. Musulmonlar haj yoki umra ziyoratlarini bajarish uchun Makkaga borishadi. Yaratganlarning eng ulug‘i Muhammad payg‘ambar ham shu shaharda tug‘ilgan, muhr
  payg‘ambarlar va elchilar, ustozimiz Muhammad sollallohu alayhi vasallamga va u zotdan Islom dinining bag‘rikenglik da’vati nozil bo‘lgach, dunyoning barcha burchaklariga yuborilgan.
  Muqaddas Qur'on. Makka Saudiya Arabistonining g'arbiy qismida joylashgan. Saudiya Arabistoni qirollari davrida u katta qiziqish uyg'otdi.
  Makka hanuzgacha ziyoratchilarga eng yaxshi xizmatlarni ko'rsatib, katta e'tiborga ega. Uning eng diqqatga sazovor joyi Masjid al-Harom.
  masjidning barcha qismlarini qamrab olgan ko‘plab kengaytirilgan ishlarga guvoh bo‘ldi va barchani ta’minlash maqsadida muqaddas joylarda bir qator obodonlashtirish ishlari amalga oshirildi.
  Qirollik ichida va tashqarisida barcha ziyoratchilar uchun qulaylik va xavfsizlik vositalari.`,

  town_1_info_h_3: `Al Madina Al Munawara`,
  town_1_info_h_4: `Madina yoki musulmonlar uni Tayba at-Toyba deb ataydilar” dunyodagi millionlab musulmonlar uchun eng muhim manzillardan biri.
  tarixi, islomning poytaxti va musulmonlar uchun Makka al-Mukarramdan keyingi ikkinchi muqaddas joy.`,
  visa_p_1: `Viza yordami`,
  visa_p_2: `Viza qo'llab-quvvatlash - viza hujjatlarini tayyorlash va ro'yxatdan o'tish bilan bog'liq barcha masalalar bo'yicha maslahat berish, viza berish uchun zarur hujjatlarni olish xizmati.
  `,
  visa_p_3: ` Viza yordami koʻpincha xorij fuqarosiga u kelishni rejalashtirgan yoki xohlayotgan mamlakatning xorij, Turkiya, Misr va boshqa mamlakatlardagi konsulligi tomonidan taqdim etiladi. Bunday hollarda chet el fuqarosi tashrif buyurishni rejalashtirayotgan mamlakat qonunchiligiga muvofiq rasmiy taklifnoma asosida viza berish. Taklifnoma jismoniy yoki yuridik shaxs, agentlik yoki Visa xizmatlarini ko‘rsatuvchi markaz taklifiga binoan berilishi mumkin.`,
};
