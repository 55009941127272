export const LANGUAGE = "app-zamaantravel-language";
export const API_PATH2 = `https://back.sultantravel.uz/${
  localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : "en"
}/`;

export const API_PATH = `https://back.silkasia.uz/${
  localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : "en"
}/`;

// export const API_PATH = `http://127.0.0.1:8000/${
//   localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : "ru"
// }/`;
