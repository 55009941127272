import React from "react";
import { getText } from "../locales";

const HeadContact = () => {
  return (
    <>
      <div className="HeadContact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="h_contact_name">{getText("h_contact_name")}</div>
            </div>

            <div className="col-md-3 col-6">
              <div className="head_contact_box">
                <img src="/img/icon_con_1.png" alt="" />
                <div className="c_head_name">{getText("c_head_name_1")}</div>
                <a href="tel:+998909993838" className="head_contact_phone">
                  +998 (90) 999 38 38
                </a>
                <a href="tel:+998900036803" className="head_contact_phone">
                  +998 (90) 003 68 03
                </a>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="head_contact_box">
                <img src="/img/icon_con_2.png" alt="" />
                <div className="c_head_name">{getText("c_head_name_2")}</div>
                <div className="head_contact_phone">
                  {getText("c_head_name_3")}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-5">
              <div className="head_contact_box">
                <img src="/img/icon_con_3.png" alt="" />
                <div className="c_head_name">{getText("c_head_name_4")}</div>
                <div className="head_contact_phone">
                  {getText("c_head_name_5")}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-5">
              <div className="head_contact_box">
                <img src="/img/icon_con_4.png" alt="" />
                <div className="c_head_name">Email</div>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="mailto: Silkasia.uz@gmail.com"
                  className="head_contact_phone"
                >
                  Silkasia.uz@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="map_company">
          <iframe
            className="map_con"
            title="Location"
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.4731764098016!2d69.33491145237639!3d41.341139794923485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef5e5315e30bb%3A0x3dfb6d2b6bf55c4b!2sSanart%20IT!5e0!3m2!1sru!2s!4v1706693234877!5m2!1sru!2s"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default HeadContact;
