export const en = {
  nav_1: `Home`,
  nav_2: `All direction`,
  nav_3: `About us`,
  nav_4: `Contacts`,
  nav_5: `Registration`,
  nav_6: `Login`,
  nav_7: `Help with visa`,

  header_box_1: `travel the world`,
  header_box_2: `Let's travel around`,
  header_box_3: `world`,
  header_box_4: `Check out`,
  header_box_5: `"Silk Asia"`,
  header_box_6: `together with your travel partner! Make your holiday fun, exciting and unforgettable.`,
  header_box_7: `Book now`,

  headpro_name: `Great Deals`,
  headpro_name_2: `Popular Dreams`,

  h_about_h: `About us`,
  h_about_sub: ` With Silk Asia, every corner of the planet has become much closer and more accessible.`,
  h_about_p_1: `The whole world in the palm of your hand. With Silk Asia, every corner of the planet has become much closer and more accessible. Traveling opens up the world for us every time we visit another country. Everything amazing is nearby, you just need to decide and take the first step.`,
  h_about_p_2: `Our new design reflects a different perspective, a completely new approach to your holiday. This is a great opportunity to replenish your baggage with new knowledge, having studied the culture and traditions of other peoples. With us you can visit the unique places of our planet, meet interesting people and get to know the taste of real Turkish coffee, Ceylon tea, the height of the Egyptian pyramids.`,

  head_info_name: `What's special about us`,
  h_info_text_h_1: `Silk Asia`,
  h_info_text_p_1: `We clearly understand the situation in the world due to the pandemic, so all our travel offers are based on safety and stability.`,
  h_info_text_h_2: `Individual approach`,
  h_info_text_p_2: `We organize tourist trips, both for individual tourists and large corporate groups for the purpose of business or leisure events.`,
  h_info_text_h_3: `In touch 24/7`,
  h_info_text_p_3: `We enter into cooperation agreements only with proven and reliable tour operators who stay in touch with our guests 24/7.`,
  h_info_text_h_4: `Best prices`,
  h_info_text_p_4: `We constantly monitor the relevance of prices and the uniqueness of offers. We have formed a large database of the best hotels around the world, depending on the budgets of our clients`,

  h_contact_name: `Contact us`,
  c_head_name_1: `Phone number`,
  c_head_name_2: `Hours of operation`,
  c_head_name_3: `Monday - Saturday from 9:00 to 18:00`,
  c_head_name_4: `Address`,
  c_head_name_5: `Tashkent city, Yashnabad district, Tashselmash street 8.`,

  footer_box_name: `INFO`,
  tour: `TOURS`,
  headpro_name_3: `All directions`,

  registr_name_1: `Registration`,
  registr_name_2: `Login`,

  registr_h_1: `Your phone number *`,
  registr_h_2: `Set password *`,
  registr_h_3: `Confirm password *`,
  registr_h_4: ` Password *`,
  registr_h_5: `Forgot your password?`,

  pay_name: `Select payment type`,

  from: `from`,

  detail_order_btn: `Book now`,
  town_name: `departure from`,
  town_h: `Tour dates:`,
  town_h2: ` *the price may change according to the exchange rate of the Central Bank"`,

  login_false: `Enter the information correctly`,
  log_out: `Log out`,
  sum: `summ`,

  card_1: `Make payment`,
  card_2: `Payment amount`,
  card_3: `Enter your card number`,
  card_4: `Expiry`,
  card_5: `Confirm`,
  card_6: `Enter verification code`,
  card_7: `Your payment has been made successfully`,

  visa_a_1: `Get a consultation`,
  visa_box_name: `WHY CHOOSE US?`,
  visa_box_name_1: `Available visa purchase`,
  visa_box_name_2: `We will help you get a visa to another country with maximum efficiency.`,
  visa_box_name_3: `Visa to most countries`,
  visa_box_name_4: `Professional visa support to the country of your choice.`,
  visa_box_name_5: `Great offers`,
  visa_box_name_6: `We will select the most favorable offers for visas and visa support for you`,
  h_plan_name_1: `Start planning your trip`,
  h_plan_name_2: `All you need to know to start planning your trip to Mecca and Medina.`,

  h_ab_name_1: `About "Silk Asia" company`,
  h_ab_name_2: `Platform for holistic travel`,
  h_ab_name_3: `Use "Silk Asia", the first ever official planning, booking and experience platform, to create a Hajj or Umrah itinerary to Mecca, Madina and other places. With "Silk Asia", travelers from all over the world can easily organize their entire visit, from applying for an e-Visa to booking hotels and flights. In the future, "Silk Asia" can also be used for scheduling visits to important sites, finding transportation, creating routes, as well as accessing on-site tools such as the Tawaf tracker, etc.`,

  btn: `More`,

  h_tour_h: `Special offers and destinations`,

  feed_name_1: `Contact a specialist`,
  feed_name_2: `Your name`,
  feed_name_3: `Your phone number:`,
  feed_name_4: `Message content:`,
  feed_name_5: `Submit`,

  map_h: `need more information? Contact us.`,

  foot_h_1: `Settings`,
  foot_h_2: `Download application "Silk Asia"`,

  head_h_1: `Visa support, transfer, medical insurance`,
  head_h_2: `Visa assistance`,
  head_h_3: `List of required documents:`,
  head_h_4: `Biometric passport for traveling abroad`,
  head_h_5: `2 color photographs`,
  head_h_6: `4x6 on a white background (women in a headscarf)`,
  head_h_7: `Vaccination certificate (vaccination certificate)`,
  head_h_8: `Health`,
  head_h_9: `Solvency`,
  head_h_10: `Spiritual preparation for the pilgrimage`,
  head_h_11: `The presence of a mahram in women under 45`,
  head_h_12: `Transfer`,
  head_h_13: `Buses and cars serving our pilgrims in Mecca and Medina`,
  head_h_14: `Medical insurance`,
  head_h_15: `What do we get with public health insurance? First of all, early detection of diseases, further improvement of the quality and volume of medical services provided to the population will be achieved. With guaranteed packages, free medical services guaranteed by the state and delivery of medicines are carried out at the level of each medical institution. As part of the package, a citizen undergoes the necessary diagnostic and laboratory tests in the regional office of a family doctor, a family clinic, and a multidisciplinary central clinic. These packages do not have an expiration date and are generic.`,

  h_plan_con_h: `Get a consultation`,

  ppay: `Pay`,

  pay_a: `Send`,

  pay_card_name: "Enter card details",

  town_text_h_1: `Best for`,
  town_text_h_2: `Religions`,
  town_text_h_3: `Culture`,
  town_text_h_4: `History`,
  town_text_h_5: `Legacy`,

  town_name_2: `Explore Madina`,

  town_1_2_box_1: `Accommodation`,
  town_1_2_box_2: `Holy places`,
  town_1_2_box_3: `Points of Interest`,
  town_1_2_box_4: `Shops`,
  town_1_2_box_5: `Food and drinks`,
  town_1_2_box_6: `How to get to Mecca`,
  town_1_2_box_6_2: `How to get to Madina`,

  town_1_info_h_1: `Holy place`,
  town_1_info_h_2: `Mecca is the holiest city for Muslims. It contains the Masjid al-Haram (Great Mosque) and the Kaaba - the qibla for Muslims, which they refer to in their
  prayers. Muslims tend to visit Mecca to perform the Hajj or Umrah. Prophet Muhammad, the most honored of creations, was born in this city, seal
  prophets and messengers, our master Muhammad, peace be upon him, and from him the tolerant message of Islam was sent to all corners of the world after being revealed
  Holy Koran. Mecca is located in the western part of Saudi Arabia. During the reign of the kings of Saudi Arabia, she enjoyed great interest.
  Mecca still enjoys great attention, providing the best services to pilgrims. Its most prominent landmark, the Masjid al-Haram,
  has witnessed numerous expansions to include all parts of the mosque, and a number of beautification works have been carried out in the holy places to provide all
  means of comfort and security for all pilgrims from within and outside the Kingdom.`,

  header_1: `Quality and Reliable Guaranteed Umrah Pilgrimages`,
  town_1_info_h_3: `Al Madina Al Munawara`,
  town_1_info_h_4: `Madina, or as Muslims call it, Tayba al-Taiba,' is one of the top destinations for millions of Muslims around the world. It is the first
  history, the capital of Islam and the second holiest place for Muslims after Mecca al-Mukarram.`,
  visa_p_1: `Visa support`,
  visa_p_2: `Visa support - a service for the preparation of visa documents and advice on all issues related to registration, obtaining the necessary documents for issuing visas.
  `,
  visa_p_3: ` Visa support is most often provided to a foreign citizen by the consulate of the country where he plans or wants to come, abroad, Turkey, Egypt and other countries. Issuance of a visa in such cases on the basis of an official invitation issued in accordance with the legislation of the country that the foreigner plans to visit. An invitation can be issued either at the invitation of an individual or a legal entity, an agency or a center providing Visa support.`,
};
