import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getText } from '../locales'
import Loader2 from './Loader2'
import { API_PATH } from '../tools/constant'
import axios from 'axios'

const Offers = () => {
    const [items, setItems] = useState([])

    const getItems = () => {
        axios.get(API_PATH + 'main/tour/?popular=True')
            .then((res) => {
                setItems(res.data)
            })
    }

    useEffect(() => {
        getItems();
    }, [])

    const nav = useNavigate()
    const link = () => {
        nav('/detail')
    }

    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false)
        }, 1600);
    })

    return (
        <>
            {isLoader ? <>< Loader2 /> </> : <>
                <div className="Offers">
                    <div className="HeadPro">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="headpro_name">
                                        {getText('headpro_name_3')}
                                    </div>
                                </div>
                                {items && items.map((item, index) => {
                                    return (
                                        <div key={index} onClick={() => nav(`/detail/${item.id}`)} className="col-lg-3 col-6">
                                            <div className="head_pro_box">
                                                <div className="head_box_img">
                                                    <img src={item.image} alt="" />
                                                    <div className="box_location">
                                                        <img src="/img/icon_loc.png" alt="" />
                                                        {item.location}
                                                    </div>
                                                </div>
                                                <div className="head_pro_loc_name">
                                                    {item.city}
                                                </div>
                                                <div className="head_pro_days">
                                                    {item.duration}
                                                </div>
                                                <div className="head_pro_price">
                                                    {getText("from")} {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} UZS
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>

                </div>
            </>}

        </>
    )
}

export default Offers