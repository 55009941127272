import React from "react";
import { getText } from "../locales";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import { API_PATH2 } from "../tools/constant";

const UmraHajj = () => {
  const [travel, setTravel] = useState([]);

  const getTravel = () => {
    axios.get(API_PATH2 + "/main/sayohat/").then((res) => {
      setTravel(res.data);
    });
  };

  useEffect(() => {
    getTravel();
  }, []);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [message, setMessage] = useState([]);

  const post = () => {
    axios
      .post(API_PATH2 + "/main/contact/", { name, phone, message })
      .then((res) => {
        console.log(res);
        setName("");
        setPhone("");
        setMessage("");
      });
  };
  return (
    <>
      <div className="UmraHajj">
        <div className="HeaderHajj">
          <div className="Header_1">
            <div className="container">
              <div className="row">
                <div className="col-12 ">
                  <div className="head_h">{getText("header_1")}</div>
                  <a href="tel:+998909993838" className="header_btn">
                    <button className="btn"> {getText("nav_4")}</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="Header_2"></div>
        </div>
        <div className="HPlan">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="h_plan_name">{getText("h_plan_name_1")}</div>
                <div className="h_plan_h">{getText("h_plan_name_2")}</div>
              </div>
              <div className="col-12">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                  pagination={{
                    type: "progressbar",
                  }}
                  loop={true}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {travel?.map((item, index) => (
                    <>
                      <SwiperSlide key={index}>
                        <div className="h_plan_box">
                          <div className="h_plan_top">
                            <img src={item?.image} alt="" />
                            <div className="h_plan_name_2">{item?.title}</div>
                          </div>
                          <div className="h_plan_down">
                            <div className="h_plan_p">{item?.content}</div>
                            <a href="tel:+998909993838" className="h_plan_con">
                              <div className="h_plan_con_h">
                                {getText("h_plan_con_h")}
                              </div>
                              <img src="/img/icon_next.png" alt="" />
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="HAbout">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="h_ab_name">{getText("h_ab_name_1")}</div>
                <div className="h_ab_h">{getText("h_ab_name_2")}</div>
                <div className="h_ab_p">{getText("h_ab_name_3")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="Feedback">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="feed_name">{getText("feed_name_1")}</div>
                <form onSubmit={post} className="feed_form" action="">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder={getText("feed_name_2")}
                    type="text"
                    name=""
                    id=""
                  />
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    placeholder={getText("feed_name_3")}
                    type="text"
                    name=""
                    id=""
                  />
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    placeholder={getText("feed_name_4")}
                    name=""
                    id=""
                  ></textarea>
                  <button type="submit" className="btn">
                    {" "}
                    {getText("feed_name_5")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UmraHajj;
