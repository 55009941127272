import React from 'react'

const Loader = () => {
    return (
        <>
            <div className="Loader2">
              
            </div>
        </>
    )
}

export default Loader